import GATSBY_COMPILED_MDX from "/codebuild/output/src3146426229/src/content/posts/2020-12-06--plagiarism-seo/index.mdx";
import PropTypes from "prop-types";
import React from "react";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import Seo from "../components/Seo";
import Article from "../components/Article";
import Post from "../components/Post";
import {ThemeContext} from "../layouts";
import Youtube from "../components/Youtube";
const PostTemplate = props => {
  const {data: {post, site: {siteMetadata: {facebook}}}, pageContext: {next, prev}, children} = props;
  const shortcodes = {
    Youtube
  };
  return React.createElement(React.Fragment, null, React.createElement(ThemeContext.Consumer, null, theme => React.createElement(Article, {
    theme: theme
  }, React.createElement(Post, {
    post: post,
    next: next,
    prev: prev,
    facebook: facebook,
    theme: theme
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children)))), React.createElement(Seo, {
    data: post,
    facebook: facebook
  }));
};
PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};
PostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const postQuery = graphql`
  query ($id: String!) {
    post: mdx(id: { eq: $id }) {
      id
      fields {
        slug
        prefix
      }
      internal {
        contentFilePath
      }
      frontmatter {
        title
        author
        category
        description
        cover {
          childImageSharp {
            resize(width: 300) {
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
